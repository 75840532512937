import { default as aboutcggDDldG7iMeta } from "/vercel/path0/pages/about.vue?macro=true";
import { default as confirmationBwVU05JSLdMeta } from "/vercel/path0/pages/confirmation.vue?macro=true";
import { default as emailSenthuXI9oLRrxMeta } from "/vercel/path0/pages/contact/emailSent.vue?macro=true";
import { default as indexDBbnZWdxZ3Meta } from "/vercel/path0/pages/contact/index.vue?macro=true";
import { default as contactJnsDpYVejzMeta } from "/vercel/path0/pages/contact.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as joinusShhBuFwN3CMeta } from "/vercel/path0/pages/joinus.vue?macro=true";
import { default as accountingDva8buUfE9Meta } from "/vercel/path0/pages/pms/accounting.vue?macro=true";
import { default as bookingsHdp9G1dMpOMeta } from "/vercel/path0/pages/pms/bookings.vue?macro=true";
import { default as clientsqcsRYc3JEFMeta } from "/vercel/path0/pages/pms/clients.vue?macro=true";
import { default as dashboardFhAgsdvubbMeta } from "/vercel/path0/pages/pms/dashboard.vue?macro=true";
import { default as rooms1xHwqLk9BbMeta } from "/vercel/path0/pages/pms/rooms.vue?macro=true";
import { default as settingsIhsHIgS6YkMeta } from "/vercel/path0/pages/pms/settings.vue?macro=true";
import { default as pmsuEyRclLYZMMeta } from "/vercel/path0/pages/pms.vue?macro=true";
import { default as pricingIYNdvfoSO6Meta } from "/vercel/path0/pages/pricing.vue?macro=true";
import { default as registrationjA8JV179ddMeta } from "/vercel/path0/pages/registration.vue?macro=true";
import { default as signin0XCmIKo45IMeta } from "/vercel/path0/pages/signin.vue?macro=true";
import { default as signupY93ILdpGpbMeta } from "/vercel/path0/pages/signup.vue?macro=true";
export default [
  {
    name: aboutcggDDldG7iMeta?.name ?? "about",
    path: aboutcggDDldG7iMeta?.path ?? "/about",
    meta: aboutcggDDldG7iMeta || {},
    alias: aboutcggDDldG7iMeta?.alias || [],
    redirect: aboutcggDDldG7iMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/about.vue").then(m => m.default || m)
  },
  {
    name: confirmationBwVU05JSLdMeta?.name ?? "confirmation",
    path: confirmationBwVU05JSLdMeta?.path ?? "/confirmation",
    meta: confirmationBwVU05JSLdMeta || {},
    alias: confirmationBwVU05JSLdMeta?.alias || [],
    redirect: confirmationBwVU05JSLdMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/confirmation.vue").then(m => m.default || m)
  },
  {
    path: contactJnsDpYVejzMeta?.path ?? "/contact",
    children: [
  {
    name: emailSenthuXI9oLRrxMeta?.name ?? "contact-emailSent",
    path: emailSenthuXI9oLRrxMeta?.path ?? "emailSent",
    meta: emailSenthuXI9oLRrxMeta || {},
    alias: emailSenthuXI9oLRrxMeta?.alias || [],
    redirect: emailSenthuXI9oLRrxMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/contact/emailSent.vue").then(m => m.default || m)
  },
  {
    name: indexDBbnZWdxZ3Meta?.name ?? "contact",
    path: indexDBbnZWdxZ3Meta?.path ?? "",
    meta: indexDBbnZWdxZ3Meta || {},
    alias: indexDBbnZWdxZ3Meta?.alias || [],
    redirect: indexDBbnZWdxZ3Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/contact/index.vue").then(m => m.default || m)
  }
],
    name: contactJnsDpYVejzMeta?.name ?? undefined,
    meta: contactJnsDpYVejzMeta || {},
    alias: contactJnsDpYVejzMeta?.alias || [],
    redirect: contactJnsDpYVejzMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index",
    path: indexe9Brt5DfdhMeta?.path ?? "/",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: joinusShhBuFwN3CMeta?.name ?? "joinus",
    path: joinusShhBuFwN3CMeta?.path ?? "/joinus",
    meta: joinusShhBuFwN3CMeta || {},
    alias: joinusShhBuFwN3CMeta?.alias || [],
    redirect: joinusShhBuFwN3CMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/joinus.vue").then(m => m.default || m)
  },
  {
    name: pmsuEyRclLYZMMeta?.name ?? "pms",
    path: pmsuEyRclLYZMMeta?.path ?? "/pms",
    children: [
  {
    name: accountingDva8buUfE9Meta?.name ?? "pms-accounting",
    path: accountingDva8buUfE9Meta?.path ?? "accounting",
    meta: accountingDva8buUfE9Meta || {},
    alias: accountingDva8buUfE9Meta?.alias || [],
    redirect: accountingDva8buUfE9Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/pms/accounting.vue").then(m => m.default || m)
  },
  {
    name: bookingsHdp9G1dMpOMeta?.name ?? "pms-bookings",
    path: bookingsHdp9G1dMpOMeta?.path ?? "bookings",
    meta: bookingsHdp9G1dMpOMeta || {},
    alias: bookingsHdp9G1dMpOMeta?.alias || [],
    redirect: bookingsHdp9G1dMpOMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/pms/bookings.vue").then(m => m.default || m)
  },
  {
    name: clientsqcsRYc3JEFMeta?.name ?? "pms-clients",
    path: clientsqcsRYc3JEFMeta?.path ?? "clients",
    meta: clientsqcsRYc3JEFMeta || {},
    alias: clientsqcsRYc3JEFMeta?.alias || [],
    redirect: clientsqcsRYc3JEFMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/pms/clients.vue").then(m => m.default || m)
  },
  {
    name: dashboardFhAgsdvubbMeta?.name ?? "pms-dashboard",
    path: dashboardFhAgsdvubbMeta?.path ?? "dashboard",
    meta: dashboardFhAgsdvubbMeta || {},
    alias: dashboardFhAgsdvubbMeta?.alias || [],
    redirect: dashboardFhAgsdvubbMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/pms/dashboard.vue").then(m => m.default || m)
  },
  {
    name: rooms1xHwqLk9BbMeta?.name ?? "pms-rooms",
    path: rooms1xHwqLk9BbMeta?.path ?? "rooms",
    meta: rooms1xHwqLk9BbMeta || {},
    alias: rooms1xHwqLk9BbMeta?.alias || [],
    redirect: rooms1xHwqLk9BbMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/pms/rooms.vue").then(m => m.default || m)
  },
  {
    name: settingsIhsHIgS6YkMeta?.name ?? "pms-settings",
    path: settingsIhsHIgS6YkMeta?.path ?? "settings",
    meta: settingsIhsHIgS6YkMeta || {},
    alias: settingsIhsHIgS6YkMeta?.alias || [],
    redirect: settingsIhsHIgS6YkMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/pms/settings.vue").then(m => m.default || m)
  }
],
    meta: pmsuEyRclLYZMMeta || {},
    alias: pmsuEyRclLYZMMeta?.alias || [],
    redirect: pmsuEyRclLYZMMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/pms.vue").then(m => m.default || m)
  },
  {
    name: pricingIYNdvfoSO6Meta?.name ?? "pricing",
    path: pricingIYNdvfoSO6Meta?.path ?? "/pricing",
    meta: pricingIYNdvfoSO6Meta || {},
    alias: pricingIYNdvfoSO6Meta?.alias || [],
    redirect: pricingIYNdvfoSO6Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: registrationjA8JV179ddMeta?.name ?? "registration",
    path: registrationjA8JV179ddMeta?.path ?? "/registration",
    meta: registrationjA8JV179ddMeta || {},
    alias: registrationjA8JV179ddMeta?.alias || [],
    redirect: registrationjA8JV179ddMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/registration.vue").then(m => m.default || m)
  },
  {
    name: signin0XCmIKo45IMeta?.name ?? "signin",
    path: signin0XCmIKo45IMeta?.path ?? "/signin",
    meta: signin0XCmIKo45IMeta || {},
    alias: signin0XCmIKo45IMeta?.alias || [],
    redirect: signin0XCmIKo45IMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/signin.vue").then(m => m.default || m)
  },
  {
    name: signupY93ILdpGpbMeta?.name ?? "signup",
    path: signupY93ILdpGpbMeta?.path ?? "/signup",
    meta: signupY93ILdpGpbMeta || {},
    alias: signupY93ILdpGpbMeta?.alias || [],
    redirect: signupY93ILdpGpbMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/signup.vue").then(m => m.default || m)
  }
]