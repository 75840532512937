<script setup>
import { registerLicense } from '@syncfusion/ej2-base';

//META
useSeoMeta({
  title: 'Ndaku',
  description: 'Simplifiez votre gestion. Maximisez votre potentiel',
  url: 'https://ndaku.ch',
  keywords:
    "ndaku, voyage, tourisme, congo, kinshasa, Logiciel de gestion immobilière,Système de gestion des réservations,Solutions micro-SaaS, Logiciel immobilier, Système de réservation en ligne, Logiciel de location immobilière, Système de gestion des locataires ,Gestion des réservations, Logiciel de réservation immobilière ,Gestion des propriétés locatives ,Système de réservation automatisé ,Logiciel de réservation immobilière ,Outils de gestion immobilière, Solutions SaaS pour l’immobilier ",
})

//DATA

//LIFE CYCLE HOOKS
onBeforeMount(() => {
  // Registering Syncfusion license key.
  registerLicense('Ngo9BigBOggjHTQxAR8/V1NCaF5cXmZCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWXdccHRTRmRfVUJyWUo=');
})
</script>

<template>
  <NuxtLoadingIndicator />
  <NuxtLayout>
    <VitePwaManifest />
    <NuxtPage />
  </NuxtLayout>
</template>

<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&family=Montserrat:wght@300;400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');


@import './node_modules/@syncfusion/ej2-base/styles/tailwind.css';
@import './node_modules/@syncfusion/ej2-buttons/styles/tailwind.css';
@import './node_modules/@syncfusion/ej2-calendars/styles/tailwind.css';
@import './node_modules/@syncfusion/ej2-dropdowns/styles/tailwind.css';
@import './node_modules/@syncfusion/ej2-inputs/styles/tailwind.css';
@import './node_modules/@syncfusion/ej2-navigations/styles/tailwind.css';
@import './node_modules/@syncfusion/ej2-popups/styles/tailwind.css';
@import './node_modules/@syncfusion/ej2-vue-schedule/styles/tailwind.css';

body {
  min-height: 100vh;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  line-height: 1.6;
  color: #1e1e1e;
  background-color: #fcfbf8;
}

a,
div,
main,
span {
  font-family: 'Montserrat', serif;
  font-weight: 400;
  line-height: 1.6;
}



/* Headers */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Playfair Display', serif;
  letter-spacing: 0.5px;
}

h1 {
  font-size: 2.5rem;
  font-weight: 700;
}

h2 {
  font-size: 2rem;
  font-weight: 400;
}

#__nuxt {
  min-height: 100vh;
}

.row,
.column,
.flex {
  display: flex;
}

.q-stepper__header .q-stepper__header--standard-labels .q-stepper__header--border .q-stepper__header--contracted {
  display: none;
}

@media (max-width: 640px) {
  .phone-hidden {
    display: none;
  }
}

@media (min-width: 640px) {
  .desktop-hidden {
    display: none;
  }
}

@keyframes shine {
  0% {
    left: -100%;
  }

  100% {
    left: 100%;
  }
}

.shiny-text {
  position: relative;
  display: inline-block;
  color: inherit;
}

.shiny-text::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  height: 100%;
  width: 100%;
  background: linear-gradient(120deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 100%);
  transform: skewX(-30deg);
  animation: shine 2s infinite;
}

.section-glass {
  background: linear-gradient(135deg, rgba(160, 160, 160, 0.8), rgba(200, 200, 200, 0.8));
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  /* border-radius: 15px; */
  padding: 20px;
  border: 1px solid rgba(255, 255, 255, 0.2);
}
</style>