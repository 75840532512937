<script setup lang="ts">

//DATA
const supabase = useSupabaseClient()
const logo = ref('')

//METHODS
const loadPics = async () => {
    logo.value = await supabase.storage.from('Pics').getPublicUrl('logo.png').data.publicUrl
}

//LIFE CYCLE HOOKS
onBeforeMount(async () => {
    await loadPics();
})
</script>

<template>
    <div class="w-48 h-24 rounded-md" :style="{
        backgroundImage: `url(${logo})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    }">
    </div>
</template>
