<script setup lang="ts">
import { onMounted } from 'vue';
import Footer from './components/layout/Footer.vue'
import TopNav from './components/landing/TopNav.vue'
import PrimaryButton from './components/buttons/PrimaryBtn.vue'

definePageMeta({
    title: 'Error',
    description: 'Error Page',
    layout: 'auth'
});


const props = defineProps({
    error: Object as () => NuxtError
})


onMounted(() => {
    console.error(props.error)
})
</script>
<template>
    <TopNav />
    <div class="mt-7 max-w-md mx-auto text-center min-h-[80vh] flex flex-col justify-center gap-12">
        <span class="mt-7 text-7xl font-bold">
            {{ error.statusCode }}
        </span>

        <span class="text-2xl">
            {{ $t(error.message) }}
        </span>

        <nuxt-link to="/" class="block">
            <PrimaryButton>
                Retour à l'accueil
            </PrimaryButton>
        </nuxt-link>
    </div>
</template>
