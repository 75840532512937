<script setup lang="ts">

</script>

<template>
  <button
    class="text-white rounded-xl px-8 py-2 text-lg bg-[#2C3E50] lg:transform lg:transition-transform lg:duration-75 active:scale-95">
    <slot />
  </button>
</template>

<style scss scoped>
button {
  border: none;
  outline: none;
  cursor: pointer;

  transition: all 0.15s ease-in-out;
}
</style>