import revive_payload_client_4sVQNw7RlN from "/vercel/path0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/vercel/path0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import supabase_client_I4Hv4qpJHd from "/vercel/path0/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.mjs";
import payload_client_yVLowv6hDl from "/vercel/path0/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/vercel/path0/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/vercel/path0/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_icons_plugin_LnrhIJeMG7 from "/vercel/path0/.nuxt/pwa-icons-plugin.ts";
import pwa_client_Eorgdr12jA from "/vercel/path0/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import plugin_client_UYiXMU8ZyN from "/vercel/path0/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import i18n_yfWm7jX06p from "/vercel/path0/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import primevue_plugin_egKpok8Auk from "/vercel/path0/.nuxt/primevue-plugin.mjs";
import plugin_client_533RIki58s from "/vercel/path0/node_modules/@primevue/nuxt-module/dist/runtime/plugin.client.mjs";
import quasar_plugin_G9rpXFQbul from "/vercel/path0/.nuxt/quasar-plugin.mjs";
import provide_4gUmUdUSZU from "/vercel/path0/node_modules/nuxt-quasar-ui/dist/runtime/provide.mjs";
import chunk_reload_client_UciE0i6zes from "/vercel/path0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_nuxt3_gGdllsjxuZ from "/vercel/path0/node_modules/nuxt-mail/dist/plugin-nuxt3.js";
import tooltip_Di9mlKVbnI from "/vercel/path0/plugins/tooltip.ts";
import vercelAnalytics_client_CnNAeCPbLY from "/vercel/path0/plugins/vercelAnalytics.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  supabase_client_I4Hv4qpJHd,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_Eorgdr12jA,
  plugin_client_UYiXMU8ZyN,
  i18n_yfWm7jX06p,
  primevue_plugin_egKpok8Auk,
  plugin_client_533RIki58s,
  quasar_plugin_G9rpXFQbul,
  provide_4gUmUdUSZU,
  chunk_reload_client_UciE0i6zes,
  plugin_nuxt3_gGdllsjxuZ,
  tooltip_Di9mlKVbnI,
  vercelAnalytics_client_CnNAeCPbLY
]