<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 14 14">
        <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
            d="M13.5 2H6m5 5H3.5m5 5h-8"></path>
    </svg>
</template>

<script lang="ts">
export default {
    name: 'SidebarIcon'
}
</script>