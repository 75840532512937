<script setup lang="ts">
import PrimaryBtn from "@/components/buttons/PrimaryBtn.vue";
import MenuBtn from "@/components/buttons/MenuBtn.vue";
import LazyHomeIcon from '@/assets/icons/HomeIcon.vue'
import LazyCreditCardIcon from '@/assets/icons/CreditCardIcon.vue'
import LazyLoginIcon from '@/assets/icons/LoginIcon.vue'
import LazyUserPlusIcon from '@/assets/icons/UserPlusIcon.vue'
import Logo from "@/components/logo/Logo.vue"
import SideBarBtn from '../buttons/SideBarBtn.vue'

///////////HOOKS
const user = useSupabaseUser()

//DATA
const timestamp = ref(0);
const dialog = ref(false);

//METHODS
const loginRoute = () => {
    if (user.value) {
        navigateTo('/pms/dashboard')
    }
    else navigateTo('/signin')
}

//COMPUTED

//LIFE CYCLE HOOKS
onBeforeMount(async () => {
    timestamp.value = Date.now();
})
</script>


<template>
    <nav class="h-full mx-2 md:mx-12">
        <ul class="flex justify-between h-full items-center">
            <li class="text-center">
                <nuxt-link to="/" class="block">
                    <Logo />
                </nuxt-link>
            </li>

            <li class=" phone-hidden sm:flex gap-8 text-black">
                <nuxt-link to="/contact" class="text-700 text-xl hover:text-[#2C3E50]">
                    Contact
                </nuxt-link>
            </li>

            <li class=" phone-hidden sm:flex gap-8 text-black">
                <nuxt-link to="/pricing" class="text-700 text-xl hover:text-[#2C3E50]">
                    Tarifs & Avantages
                </nuxt-link>
            </li>


            <div class="flex flex-row items-center gap-8 phone-hidden " v-if="user">
                <nuxt-link to="/pms/dashboard" class="">
                    <Button raised class="px-6 py-4 text-white bg-[#2C3E50] rounded-lg sm:py-4">
                        Acceuil
                    </Button>
                </nuxt-link>
            </div>

            <div class="flex flex-row items-center gap-8 phone-hidden" v-else>
                <Button raised class="px-6 py-4 text-white bg-[#2C3E50] rounded-lg sm:py-4" @click="loginRoute">
                    Connexion
                </Button>
            </div>

            <SideBarBtn class="sm:hidden text-black mr-8" @click="dialog = true" />
        </ul>
    </nav>

    <q-dialog v-model="dialog" position="right" maximized>
        <q-card style="width: 300px">
            <q-card-section class="row items-center no-wrap">

                <q-list class="w-full active:text-[#532031]" padding>
                    <q-item clickable :to="{ path: '/pms/dashboard' }" v-if="user" active-class="active">
                        <q-item-section>
                            <q-item-label>Accueil</q-item-label>
                        </q-item-section>
                        <q-item-section avatar>
                            <LazyHomeIcon />
                        </q-item-section>
                    </q-item>
                    <q-separator spaced v-if="user" />

                    <q-item clickable :to="{ path: '/pricing' }" active-class="active">
                        <q-item-section>
                            <q-item-label>Tarifs & Avantages</q-item-label>
                        </q-item-section>
                        <q-item-section avatar>
                            <LazyCreditCardIcon />
                        </q-item-section>
                    </q-item>

                    <q-item :to="{ path: '/contact' }" clickable active-class="active">
                        <q-item-section>
                            <q-item-label>Contact</q-item-label>
                        </q-item-section>
                        <q-item-section avatar>
                            <LazyUserPlusIcon />
                        </q-item-section>
                    </q-item>

                    <q-separator spaced />

                    <q-item clickable @click="loginRoute" v-if="!user" active-class="active">
                        <q-item-section>
                            <q-item-label>Connexion</q-item-label>
                        </q-item-section>
                        <q-item-section avatar>
                            <LazyLoginIcon />
                        </q-item-section>
                    </q-item>
                </q-list>
            </q-card-section>
        </q-card>
    </q-dialog>
</template>

<style scoped>
.active {
    background-color: #f5f5f5;
    color: #0b1a28;
}
</style>
